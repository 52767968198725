import React, {useCallback, useEffect, useState} from 'react'
import './App.css'
import styled from 'styled-components'

const CurrentLetter = styled.div`
  font-size: 7rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const LetterDisplay = styled.div`
  width: 10ch;
  height: 1em;
  font-size: 5rem;
  padding: 20px;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
`

const Container = styled.div`
  padding: 100px;
  text-align: center;
  background-color: #baef70;
`

const Icon = styled.div`
  font-size: 5rem;
  position: absolute;
  top: 30%;
  left: 57%;
`

const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

const randomLetter = () => {
  const randomIndex = Math.floor(Math.random() * letters.length)

  return letters[randomIndex]
}

const hurayMp3 = new Audio('/huray.mp3');
const nieMp3 = new Audio('/nie.mp3');

function App () {
  const [expectedLetter, setExpectedLetter] = useState(randomLetter())
  const [typedLetter, setTypedLetter] = useState('')
  const [icon, setIcon] = useState<string | null>(null)

  const displayIcon = useCallback((newIcon: string) => {
    setIcon(newIcon)
    setTimeout(() => {
      setIcon(null)
    }, 800)
  }, [icon, setIcon])

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    console.log('handleKeyDown')
    if (!letters.includes(event.key.toUpperCase())) {
      return;
    }

    setTypedLetter(event.key)
    if (event.key.toLowerCase() === expectedLetter.toLowerCase()) {
      hurayMp3.play();
      setIcon("😍")
      setTimeout(() => {
        setExpectedLetter(randomLetter())
        setTypedLetter('')
        setIcon(null)
      }, 2000)
    } else {
      nieMp3.play();
      displayIcon("❌")
    }
  }, [expectedLetter, setTypedLetter])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return function cleanup () {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <div className="App">
      <Container>
        <CurrentLetter>{expectedLetter}</CurrentLetter>
        <LetterDisplay>{typedLetter}</LetterDisplay>
        {icon && <Icon>{icon}</Icon>}

      </Container>
    </div>
  )
}

export default App
